<template>
  <div>
    <!--顶部工具条-->
    <el-col :span="24">
      <el-form :inline="true">
        <el-form-item label="知识库名称：">
          <el-input v-model="filters.knowledgeName" @input="(e) => (filters.knowledgeName = utils.validForbid(e))"
            placeholder="请输入书籍名称" clearable @clear='clearContent'></el-input>
        </el-form-item>
        <el-form-item>
          <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
        </el-form-item>
      </el-form>
    </el-col>
    <!--列表-->
    <el-table :data="dataKnowledge" highlight-current-row @current-change="selectCurrentRow" v-loading="listLoading"
      @selection-change="selsChange" style="width: 100%;" border>
      <!-- <el-table-column type="selection" width="50">
      </el-table-column> -->
      <!-- <el-table-column type="index" width="80"></el-table-column> -->
      <el-table-column prop="knowledgeName" label="知识库名称" width="" sortable>
        <template slot-scope="scope">
          <el-link type="primary" @click="viewKnowledgeDialog(scope.row)">{{ scope.row.knowledgeName }}</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="dataTargetName" label="子分类" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <div v-if="scope.row.contentTypeId === 1">
            {{ scope.row.contentTypeName }}
          </div>
          <div v-else>
            {{ scope.row.dataTypeName }}{{ scope.row.dataTypeName && scope.row.dataTargetName ? ' | ' : ''
            }}{{ scope.row.dataTargetName
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="bizTypeName" label="书籍分类" width="120"></el-table-column>
      <el-table-column prop="contentTypeName" label="知识分类" width="100"></el-table-column>
      <el-table-column prop="bookCount" label="书籍数量" width="120" sortable></el-table-column>
      <el-table-column prop="createUserName" label="添加人" width="100" sortable></el-table-column>
      <el-table-column prop="createTime" label="添加时间" width="170" sortable></el-table-column>
    </el-table>
    <el-col :span="24" class="pageBar" align="center">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="pages.pageIndex" :page-sizes="pages.pageArr" :page-size="pages.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="pages.dataCount">
      </el-pagination>
    </el-col>
    <!--新增-->
    <el-dialog title="新增" :visible.sync="addFormVisible" v-model="addFormVisible" :close-on-click-modal="false">
      <el-form v-if="addFormVisible" :model="addForm" label-width="150px" :rules="addFormRules" ref="addForm">
        <el-form-item label="知识库名称：" prop="knowledgeName">
          <el-input v-model="addForm.knowledgeName" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="导入书籍：" prop="Code">
          <el-link icon="el-icon-document-add" type="primary" @click="importBooksDialog">选择书籍</el-link>
        </el-form-item>
        <el-form-item label="至少导入一本书：" prop="bookIds">
          <el-table :data="viewBooks" style="width: 100%" :show-header="showHeader" border>
            <el-table-column prop="bizTypeName" width="150">
              <template slot-scope="scope">
                <el-tag type="info" color="#FFB143"><span style="color: #FFFFFF;">{{ scope.row.bizTypeName }}</span>
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="bookName" width="280">
            </el-table-column>
            <el-table-column align="right">
              <template slot-scope="scope">
                <el-link type="primary" @click="handleAddBookDel(scope.$index, scope.row)">移除书籍
                </el-link>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="知识分类:" prop="contentTypeId">
          <el-select v-model="addForm.contentTypeId" clearable @change="addForm.dataTypeId === null">
            <el-option v-for="item in contentTypeData" :key="item.key" :label="item.value" :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="书籍分类:" prop="bizTypeId">
          <el-select v-model="addForm.bizTypeId" clearable @change="bizTypeChange">
            <el-option v-for="item in bizTypeData" :key="item.key" :label="item.value" :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="addForm.bizTypeId === 1 && addForm.contentTypeId === 2" label="培训类型:" prop="dataTypeId">
          <el-radio-group v-model="addForm.dataTypeId" @change="dataTypeChange">
            <el-radio v-for="item in dataTypeData" :key="item.key" :label="item.key">{{ item.value
              }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="addForm.bizTypeId === 2 && addForm.contentTypeId === 2" label="培训类型:" prop="dataTypeId">
          <el-radio-group v-model="addForm.dataTypeId" @change="dataTypeChange">
            <el-radio v-for="item in dataTypeData2" :key="item.key" :label="item.key">{{ item.value
              }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="addForm.bizTypeId === 2 && addForm.dataTypeId === 4" label="操作员分类:" prop="dataTargetId">
          <el-select v-model="addForm.dataTargetId" clearable>
            <el-option v-for="item in knowledgeData" :key="item.targetId" :label="item.targetName"
              :value="item.targetId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="addForm.bizTypeId === 1 && addForm.dataTypeId === 2" label="岗位分类:" prop="dataTargetId">
          <el-select v-model="addForm.dataTargetId" clearable>
            <el-option v-for="item in stationData" :key="item.targetId" :label="item.targetName" :value="item.targetId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="addForm.bizTypeId === 1 && addForm.dataTypeId === 3" label="行业分类:" prop="dataTargetId">
          <el-select v-model="addForm.dataTargetId" clearable>
            <el-option v-for="item in tradeData" :key="item.targetId" :label="item.targetName" :value="item.targetId">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="addFormVisible = false">取消</el-button>
        <el-button type="primary" @click.native="addSubmit" :loading="addLoading">提交</el-button>
      </div>
    </el-dialog>
    <el-dialog title="导入书籍" :visible.sync="importVisible" v-model="importVisible" :close-on-click-modal="false">
      <el-form :inline="true">
        <el-form-item label="书籍名称：">
          <el-input v-model="bookFilters.bookName" placeholder="请输入书籍名称" clearable @clear='clearContent'></el-input>
        </el-form-item>
        <el-form-item label="书籍类别：">
          <el-select v-model="bookFilters.contentTypeId" placeholder="请选择" clearable @clear='clearContent'>
            <el-option v-for="item in contentTypes" :key="item.key" :label="item.value" :value="item.key"
              placeholder="请选择">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getBooks">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table ref="filterTable" :data="bookList" style="width: 100%" @selection-change="handleBookSelectionChange"
        border>
        <el-table-column type="selection" width="60" :selectable="isRowSelectable">
        </el-table-column>
        <el-table-column prop="contentTypeName" label="知识分类" sortable width="180" column-key="date">
        </el-table-column>
        <el-table-column prop="bizTypeName" label="书籍分类" width="180">
        </el-table-column>
        <el-table-column prop="bookName" label="书籍名称">
        </el-table-column>
      </el-table>
      <el-col :span="24" class="pageBar">
        <el-pagination @size-change="handleBookSizeChange" @current-change="handleBookCurrentChange"
          :current-page="bookPages.pageIndex" :page-sizes="bookPages.pageArr" :page-size="bookPages.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="bookPages.dataCount">
        </el-pagination>
      </el-col>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="cancelImportBooks">取消</el-button>
        <el-button type="primary" @click.native="importBooks">导入</el-button>
      </div>
    </el-dialog>
    <el-dialog title="查看知识库" :visible.sync="viewVisible" v-model="viewVisible" @close="viewDialogClose"
      :close-on-click-modal="false">
      <el-row>
        <el-col :span="22">
          <div class="grid-content bg-purple-dark" style="font-size: 24px">{{ this.knowledgeInfo.knowledgeName }}</div>
        </el-col>
        <el-col :span="2">
          <div class="grid-content bg-purple-dark">
            <el-button type="primary" plain size="small" @click="handleEdit()">编辑
            </el-button>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" style="margin: 10px 0px">
          <el-link icon="el-icon-document-add" type="primary" @click="importBooksDialog">选择书籍</el-link>
        </el-col>
      </el-row>
      <el-table ref="viewBookTable" :data="knowledgeInfo.bookList" style="width: 100%" border>
        <el-table-column type="index" width="50"></el-table-column>
        <el-table-column prop="bookName" align="center" label="书籍名称">
          <template slot-scope="scope">
            <el-link type="primary" @click="checkBookContent(scope.row)">{{ scope.row.bookName }}</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="importTime" label="导入时间" align="center" width="200">
        </el-table-column>
        <el-table-column prop="difyStatus" label="导入状态" width="120" align="center">
          <template slot-scope="scope">
            <el-switch disabled v-model="scope.row.difyStatus">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="enableStatus" label="启用状态" width="120" align="center">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.enableStatus" @change="handleEnableStatusChange(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120" align="center">
          <template slot-scope="scope">
            <el-link type="primary" @click="handleBookDel(scope.$index, scope.row)">移除
            </el-link>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!--编辑-->
    <el-dialog title="编辑" :visible.sync="editFormVisible" v-model="editFormVisible" :close-on-click-modal="false">
      <el-form :model="editForm" label-width="120px" :rules="editFormRules" ref="editForm">
        <el-form-item label="知识库名称:" prop="Name">
          <el-input v-model="editForm.knowledgeName" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="知识分类:" prop="contentTypeId">
          <el-select v-model="editForm.contentTypeId" clearable @change="editForm.dataTypeId === null">
            <el-option v-for="item in contentTypeData" :key="item.key" :label="item.value" :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="书籍分类:" prop="bizTypeId">
          <el-select v-model="editForm.bizTypeId" clearable @change="bizTypeChange">
            <el-option v-for="item in bizTypeData" :key="item.key" :label="item.value" :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="editForm.bizTypeId === 1 && editForm.contentTypeId === 2" label="培训类型:" prop="dataTypeId">
          <el-radio-group v-model="editForm.dataTypeId" @change="dataTypeChange">
            <el-radio v-for="item in dataTypeData" :key="item.key" :label="item.key">{{ item.value
              }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="editForm.bizTypeId === 2 && editForm.contentTypeId === 2" label="培训类型:" prop="dataTypeId">
          <el-radio-group v-model="editForm.dataTypeId" @change="dataTypeChange">
            <el-radio v-for="item in dataTypeData2" :key="item.key" :label="item.key">{{ item.value
              }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="editForm.bizTypeId === 2 && editForm.dataTypeId === 4" label="操作员分类:" prop="dataTargetId">
          <el-select v-model="editForm.dataTargetId" clearable>
            <el-option v-for="item in knowledgeData" :key="item.targetId" :label="item.targetName"
              :value="item.targetId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="editForm.bizTypeId === 1 && editForm.dataTypeId === 2" label="岗位分类:" prop="dataTargetId">
          <el-select v-model="editForm.dataTargetId" clearable>
            <el-option v-for="item in stationData" :key="item.targetId" :label="item.targetName" :value="item.targetId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="editForm.bizTypeId === 1 && editForm.dataTypeId === 3" label="行业分类:" prop="dataTargetId">
          <el-select v-model="editForm.dataTargetId" clearable>
            <el-option v-for="item in tradeData" :key="item.targetId" :label="item.targetName" :value="item.targetId">
            </el-option>
          </el-select>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="editFormVisible = false">取消</el-button>
        <el-button type="primary" @click.native="editSubmit" :loading="editLoading">提交</el-button>
      </div>
    </el-dialog>
    <BookMenu v-if="showBookMenu" :visible.sync="showBookMenu" :bookId="itemValue.bookId" :title="itemValue.bookName"
      :menuType="'knowledge'" :knowledgeId="knowledgeInfo.knowledgeId">
    </BookMenu>
  </div>
</template>
<script>
import util from '../../../util/date';
import {
  getByNamespace,
  getBookList,
  addKnowledge,
  getKnowledgeDataPageList,
  updateKnowledge,
  deleteKnowledge,
  viewKnowledge,
  associationBook,
  cancelAssociationBook,
  changeBookStatus,
  getKnowledgeListByBizType,
} from '../../api/api';
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import BookMenu from "../../components/BookMenu";

export default {
  components: { Toolbar, BookMenu },
  data() {
    return {
      utils: '',
      dataKnowledge: [],
      listLoading: false,
      currentRow: null,
      //列表选中列
      sels: [],
      //增删改查按钮
      buttonList: [],
      //顶部筛选条件
      filters: {
        knowledgeName: ''
      },
      //新增界面数据书籍筛选条件
      bookFilters: {
        bookName: "",
        contentTypeId: ""
      },
      pages: {
        pageSize: 20,//默认每页条数
        pageArr: [2, 5, 10, 20, 30, 40],//默认每页条数选择
        pageIndex: 1, //默认进入页
        dataCount: 20, //默认总页数
      },
      bookPages: {  //关于分页的obj
        pageSize: 20,//默认每页条数
        pageArr: [2, 5, 10, 20, 30, 40],//默认每页条数选择
        pageIndex: 1, //默认进入页
        dataCount: 20, //默认总页数
      },
      //新增界面是否显示
      addFormVisible: false,
      addLoading: false,
      addFormRules: {
        knowledgeName: [
          { required: true, message: '请输入知识库名称', trigger: 'blur' }
        ],
        bookIds: [
          { required: true, message: '请导入至少一本书籍', trigger: 'blur' }
        ]
      },
      //dialog数据
      addForm: {
        knowledgeName: '',
        bookIds: [],
        bizTypeId: "",
        contentTypeId: "",
        dataTypeId: "",
        dataTargetId: "",
      },
      //编辑界面是否显示
      editFormVisible: false,
      editLoading: false,
      editFormRules: {
        knowledgeName: [
          { required: true, message: '请输入知识库名称', trigger: 'blur' }
        ]
      },
      //dialog数据
      editForm: {
        knowledgeName: '',
        knowledgeId: '',
        bizTypeId: "",
        contentTypeId: "",
        dataTypeId: "",
        dataTargetId: "",
      },
      importVisible: false,
      bookList: [],
      showHeader: false,
      contentTypes: [],
      selectBooks: [],
      viewBooks: [],
      viewVisible: false,
      knowledgeInfo: {},
      viewRow: null,
      itemValue: null,
      showBookMenu: false,
      bizTypeData: [

      ],
      contentTypeData: [

      ],
      dataTypeData: [

      ],
      dataTypeData2: [

      ],
      knowledgeData: [],
      stationData: [],
      tradeData: [],
    };
  },
  methods: {
    // 筛选框清空，重新获取数据
    clearContent() {
      this.getBooks();
    },
    //当前行发生变化时
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    //选项发生变化时
    selsChange: function (sels) {
      this.sels = sels;
    },
    //显示新增界面
    handleAdd() {
      this.addForm = {
        knowledgeName: '',
        bookIds: [],
        bizTypeId: "",
        contentTypeId: "",
        dataTypeId: "",
        dataTargetId: "",
      };
      this.viewBooks = []
      this.addFormVisible = true;
    },
    //显示编辑界面
    handleEdit() {
      let row = this.viewVisible ? this.viewRow : this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error"
        });
        return;
      }
      this.editForm = Object.assign({}, row);
      this.editFormVisible = true;
    },
    //新增
    addSubmit: function () {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$confirm('确认提交吗？', '提示', {}).then(() => {
            this.addLoading = true;
            let params = Object.assign({}, this.addForm);
            addKnowledge(params).then((res) => {
              if (util.isEmt.format(res)) {
                this.addLoading = false;
                return;
              }
              if (res.data.success) {
                this.addLoading = false;
                this.$message({
                  message: res.data.message,
                  type: 'success'
                });
                this.addFormVisible = false;
                this.getKnowledgeList();
              } else {
                this.addLoading = false;
                this.$message({
                  message: res.data.message,
                  type: 'error'
                });
              }
            });
          });
        } else {
          this.$message({
            message: "输入参数错误",
            type: "error"
          });
        }
      });
    },
    //编辑
    editSubmit: function () {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.$confirm('确认提交吗？', '提示', {}).then(() => {
            let params = Object.assign({}, this.editForm);
            updateKnowledge(params).then((res) => {
              if (util.isEmt.format(res)) {
                this.editLoading = false;
                return;
              }
              if (res.data.success) {
                this.editLoading = false;
                this.$message({
                  message: res.data.message,
                  type: 'success'
                });
                this.editFormVisible = false;
                this.getKnowledgeList();
                this.getKnowledgeInfo(this.currentRow.knowledgeId);
              } else {
                this.editLoading = false;
                this.$message({
                  message: res.data.message,
                  type: 'error'
                });
              }
            });
          });
        }
      })
    },
    //删除
    handleDel() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要删除的一行数据！",
          type: "error"
        });
        return;
      }
      this.$confirm('确认删除该知识库吗?', '提示', {
        type: 'warning'
      }).then(() => {
        this.listLoading = true;
        let params = { knowledgeId: row.knowledgeId };
        deleteKnowledge(params).then((res) => {
          if (util.isEmt.format(res)) {
            this.listLoading = false;
            return;
          }
          if (res.data.success) {
            this.listLoading = false;
            this.$message({
              message: res.data.message,
              type: 'success'
            });
            this.userFormVisible = false;
            this.getKnowledgeList();
          } else {
            this.listLoading = false;
            this.$message({
              message: res.data.message,
              type: 'error'
            });

          }
        });
      }).catch(() => {

      });
    },
    getDataList() {
      this.pages.pageIndex = 1
      this.getKnowledgeList();
    },
    getKnowledgeList() {
      let params = this.filters;
      params.forKnowledge = true;
      params.pageIndex = this.pages.pageIndex;
      params.pageSize = this.pages.pageSize;
      getKnowledgeDataPageList(params).then((res) => {
        this.dataKnowledge = res.data.response.data;
        this.pages.dataCount = res.data.response.dataCount;
      })
    },
    handleCurrentChange(val) {
      this.pages.pageIndex = val;
      this.getKnowledgeList();
    },
    handleSizeChange(val) {
      this.pages.pageSize = val
      this.getKnowledgeList();
    },
    //获取书籍知识分类列表
    getDataContentType() {
      let params = {
        namespace: "BizService.OmsAicsService.ContentType",
      };
      getByNamespace(params).then((res) => {
        this.contentTypes = res.data.response;
      });
    },
    getBooks() {
      let params = this.bookFilters;
      params.forKnowledge = true;
      params.pageIndex = this.bookPages.pageIndex;
      params.pageSize = this.bookPages.pageSize;

      getBookList(params).then((res) => {
        this.bookList = res.data.response.data;
        this.bookPages.dataCount = res.data.response.dataCount;
      }).then(() => {
        this.$nextTick(() => {
          let bookIds = [];
          if (this.viewVisible) {
            bookIds = this.knowledgeInfo.bookList.map(item => item.bookId);
          } else {
            bookIds = this.viewBooks.map(item => item.bookId);
          }
          this.$refs.filterTable.clearSelection(); // 取消所有行的选择
          const selectedRows = this.bookList.filter(row => bookIds.includes(row.bookId));
          selectedRows.forEach(row => {
            this.$refs.filterTable.toggleRowSelection(row, true);
            row.disabled = true;
          }); // 选中
        })
      });
    },
    handleBookCurrentChange(val) {
      this.bookPages.pageIndex = val;
      this.getBooks();
    },
    handleBookSizeChange(val) {
      this.bookPages.pageSize = val
      this.getBooks();
    },
    handleBookSelectionChange(selection) {
      this.$nextTick(() => {
        this.selectBooks = [];
        selection.forEach((item) => {
          this.selectBooks.push(
            {
              bookId: item.bookId,
              bizTypeName: item.bizTypeName,
              bookName: item.bookName,
            });
        })
      });

    },
    handleAddBookDel(index, row) {
      this.$confirm('确认移除书籍吗?', '提示', {
        type: 'warning'
      }).then(() => {
        this.addForm.bookIds.splice(index, 1);
        this.viewBooks.splice(index, 1);
      });
    },
    handleBookDel(index, row) {
      this.$confirm('确认删除知识库吗?', '提示', {
        type: 'warning'
      }).then(() => {
        let params = {
          bookId: row.bookId,
          knowledgeId: this.viewRow.knowledgeId
        }
        cancelAssociationBook(params).then((res) => {
          if (res.data.success) {
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.getKnowledgeList();
            this.getKnowledgeInfo(this.viewRow.knowledgeId);
          } else {
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          }
        })
      });
    },
    cancelImportBooks() {
      this.$refs.filterTable.clearSelection();
      this.importVisible = false;
    },
    importBooksDialog() {
      this.importVisible = true;
      this.getBooks();
    },
    importBooks() {
      let bookIds = this.selectBooks.map((item) => item.bookId);
      this.viewBooks = this.selectBooks;
      if (this.viewVisible) {
        this.$confirm('确认导入知识库吗?', '提示', {
          type: 'warning'
        }).then(() => {
          let params = {
            "bookIds": bookIds,
            "knowledgeId": this.viewRow.knowledgeId
          }
          associationBook(params).then((res) => {
            if (res.data.success) {
              this.$message({
                message: res.data.message,
                type: 'success'
              })
              this.getKnowledgeInfo(this.viewRow.knowledgeId);
            } else {
              this.$message({
                message: res.data.message,
                type: 'error'
              });
            }
          })
        });
      } else {
        this.addForm.bookIds = bookIds;
      }
      this.$refs.filterTable.clearSelection(); // 取消所有行的选择
      this.importVisible = false;
    },
    getKnowledgeInfo(knowledgeId) {
      let params = { knowledgeId: knowledgeId };
      viewKnowledge(params).then((res) => {
        this.knowledgeInfo = res.data.response;
      });
    },
    viewKnowledgeDialog(row) {
      this.getKnowledgeInfo(row.knowledgeId);
      // let params = {knowledgeId: row.knowledgeId};
      // viewKnowledge(params).then((res) => {
      //   this.knowledgeInfo = res.data.response;
      //   this.viewVisible = true;
      // });
      this.viewVisible = true;
      this.viewRow = row;
    },
    viewDialogClose() {
      this.viewRow = {};
      this.knowledgeInfo = {};
    },
    handleEnableStatusChange(row) {
      let enableStr = row.enableStatus ? '启用' : '禁用';
      this.$confirm('确认' + enableStr + '知识库吗?', '提示', {
        type: 'warning'
      }).then(() => {
        let params = {
          knowledgeId: this.knowledgeInfo.knowledgeId,
          bookId: row.bookId,
          status: row.enableStatus
        };
        changeBookStatus(params).then((res) => {
          if (res.data.success) {
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.getKnowledgeInfo(this.viewRow.knowledgeId);
          } else {
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          }
        })
      });
    },
    isRowSelectable(row) {
      return !row.disabled;
    },
    callFunction(item) {
      this[item.func].apply(this, item);
    },
    checkBookContent: function (row) {
      this.itemValue = row
      this.showBookMenu = true

    },
    dataTypeChange(e) {
      this.editForm.dataTargetId = null
      this.addForm.dataTargetId = null

    },
    bizTypeChange(e) {
      this.editForm.dataTargetId = null
      this.addForm.dataTargetId = null
      this.addForm.dataTypeId = null
      this.editForm.dataTypeId = null
    },
    getbizTypeList() {
      getByNamespace({ namespace: 'BizService.OmsAicsService.BizType' }).then(res => {
        this.bizTypeData = res.data.response
      })
    },
    getBookTypeList() {
      getByNamespace({ namespace: 'BizService.OmsAicsService.BookType' }).then(res => {
        this.bookTypeData = res.data.response
      })
    },
    getDataTypeList() {
      getByNamespace({ namespace: 'BizService.OmsAicsService.DataType' }).then(res => {
        let arr = [],
          arr2 = []
        if (res.data.success) {
          res.data.response.map((item) => {
            if (item.key === 1) {
              arr.push(item)
              arr2.push(item)
            } else if (item.key === 2 || item.key == 3) {
              arr.push(item)
            } else {
              arr2.push(item)
            }
          })
        }
        this.dataTypeData = arr
        this.dataTypeData2 = arr2
      })
    },
    getContentTypeList() {
      getByNamespace({ namespace: 'BizService.OmsAicsService.ContentType' }).then(res => {
        this.contentTypeData = res.data.response
      })
    },
    _getKnowledgeData(bizType, type) {
      getKnowledgeListByBizType({ bizType, type }).then(res => {
        if (bizType === 1 && type === 2) {
          this.tradeData = res.data.response
        }
        if (bizType === 1 && type === 1) {
          this.stationData = res.data.response
        }
        if (bizType === 2) {
          this.knowledgeData = res.data.response
        }
      })
    },
  },
  mounted() {
    this.utils = util
    this.getDataContentType();
    this.getKnowledgeList();
    this.getbizTypeList()
    this.getDataTypeList()
    this.getContentTypeList()
    this._getKnowledgeData(1, 1)
    this._getKnowledgeData(1, 2)
    this._getKnowledgeData(2, 1)
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers);
  }
};
</script>
<style lang="stylus" scoped>
.rolesTop {
	width: 100%;
	display: flex;
}

.rolesInp {
	width: 300px;
	margin-right: 20px;
}

.pageBar {
  	margin-top: 15px;
}



</style>